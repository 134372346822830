;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"adbc580b5e132c720922b045058863b3c984999f"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import packageJson from './package.json';

Sentry.init({
	environment: process.env.NEXT_PUBLIC_PLATFORM,
	dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
	release: `v${packageJson.version}`,
	sampleRate: 1.0,
	tracesSampleRate: 0.3,
	profilesSampleRate: 0.5,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		Sentry.replayIntegration({
			maskAllText: true,
			blockAllMedia: true,
		}),
		Sentry.browserTracingIntegration({ enableInp: true }),
		Sentry.browserProfilingIntegration(),
	],
});
